/** @jsx jsx */
import React from 'react';
import { LinkToStacked } from './CustomLinkToStacked';
import { Link } from 'gatsby';
import { Styled, jsx, Heading } from 'theme-ui';

import useWindowWidth from '../utils/useWindowWidth';

export default ({ references }) => {
  const [width] = useWindowWidth();

  if (references.length > 0) {
    const RefLink = width < 768 ? Link : LinkToStacked;

    return (
      <>
        <Heading as="h4" color="gray">
          Links to this note
        </Heading>
        <div sx={{ mb: 2 }}>
          {references.map((reference) => {
            return (
              <RefLink
                sx={{
                  textDecoration: 'none',
                  color: 'text-light',
                  ':hover': {
                    // color: 'text',
                    bg: 'light-gray'
                  },
                }}
                to={`/${reference.slug}`}
                key={reference.slug}
              >
                <div sx={{ py: 2 }}>
                  <Styled.p sx={{ fontSize: '10px', m: 0, color: 'black' }}>
                    {reference.title}
                  </Styled.p>
                  <Styled.p sx={{ fontSize: '10px', m: 0, color: 'gray' }}>
                    {reference.childMdx.excerpt}
                  </Styled.p>
                </div>
              </RefLink>
            );
          })}
        </div>
        {/* <hr sx={{ mx: 'auto', width: 64 }} /> */}
      </>
    );
  }
  return null;
};