import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'theme-ui';

export default ({ siteMetadata }) => (
  <header>
    <Box py={2} px={3} sx={{ borderBottom: '1px solid', borderColor: 'light-gray' }}>
      <Link to="/" sx={{ display: 'inline', fontWeight: 'bold', color: 'text', textDecoration: 'none' }}>
        About these notes
      </Link>
    </Box>
  </header>
);